@import "../../colors";

.page-header {
  background-image: url("../../../public/page-header.png");
  background-size: cover;
  background-position: bottom;
  padding-top: 130px;
  padding-bottom: 100px;

  h1 {
    text-align: center;
    font-size: 44px;
    text-transform: uppercase;
    text-shadow: 4px 4px 0px $tekhelet;
  }
}