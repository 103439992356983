@import "../../colors";

.create-account {
  .type-switch {
    text-align: center;
  }

  .senior{
    margin-top: 60px;
  }

  small {
    opacity: 0.7;
    display: block;
    margin-top: 5px;
    font-style: italic;
  }
}