.login {
  .login-form {
    label {
      margin-top: 20px;
    }

    .formActionsRow {
      text-align: center;
    }
  }

  .forgot-password{
    margin-top: 20px;
    display: block;
    text-decoration: none;
    font-style: italic;
  }
}