.fact{
  display: flex;
  background: rgba(255,255,255,0.1);
  border-radius: 5px;
  padding: 20px;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
  backdrop-filter: blur(2px);

  .fact-icon{
    font-size: 40px;
    line-height: 40px;
  }

  .fact-content{
    margin-left: 20px;

    span {
      display: block;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-style: italic;
      opacity: 0.7;
      font-size: 14px;
    }
  }
}