@import "../../colors";

.signup{
  h2{
    margin: 0;
  }

  h2 + p{
    margin-top: 10px;
  }

  .signup-button{
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s ease all;

    svg{
      font-size: 30px;
      margin-right: 10px;
      transition: 0.3s ease all;
    }

    &:before{
      content: '✔';
      display: block;
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 10px;
      opacity: 0.5;
      margin-right: 20px;
      transition: 0.3s ease all;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      color: white;
    }

    &.active{
      color: white;
      border-color: white;

      &:before{
        opacity: 1;
        box-shadow: 5px 5px 0px 0px $vanilla;
        color: $earth-yellow;
      }

      svg{
        color: $jordy-blue;
      }
    }

    &.disabled{
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  .amount-input{
    .MuiInputAdornment-root {
      margin-left: 20px;

      .MuiTypography-root {
        color: white;
        font-weight: bold;
        font-size: 24px !important;
      }
    }

    input{
      font-size: 24px !important;
      padding-left: 0 !important;
    }
  }

  .small{
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-style: italic;
    color: white;
    margin-top: 10px;
  }
}