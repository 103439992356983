@import '../../colors';

.not-found{
  padding-top: 200px;
  text-align: center;

  h1{
    font-size: 200px;
    margin: 0;
    text-shadow: 10px 10px 0px $jordy-blue;
    line-height: 200px;
  }

  h2{
    color: $vanilla;
    margin: 0;
    margin-top: 30px;
    font-size: 40px;
  }

  p{
    font-size: 18px;
    margin-bottom: 30px;
  }
}