@import url('react-notifications/lib/notifications.css');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import "colors";

:root {
  --darkblue: #1136A8;
  --lightblue: #1E5EDC;
  --grey: #4B4B4B;
  --lightgrey: #606060;
  --lighterblue: #00d5ff
}

*:focus {
  outline: none;
}

svg {
  vertical-align: text-top;
}

body {
  font-family: "Lato", sans-serif !important;
  background-image: url("../public/background.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
  color: white;
}

.App{
  min-height: 100vh;
  padding-bottom: 150px;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
    font-family: "Oswald", sans-serif;
}

.customSwitch {
  position: relative;
  padding-left: 55px;
  height: 25px;
  line-height: 25px;
  font-size: 15px;
  color: var(--lightgrey);
  cursor: pointer;
}

.notification-container {
  width: unset;
  overflow: visible;
}

.notification-container .notification {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.notification-container h4 {
  color: white !important;
  font-size: 16px !important;
}

.notification-container .message {
  font-size: 14px !important;
}

.btn {
  font-family: "Oswald", sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  border-radius: 5px !important;
  padding: 10px 30px !important;
  transition: all 0.2s ease-in-out;
  font-size: 14px !important;
  line-height: 18px;
  background: white !important;
  min-width: unset !important;

  &:disabled{
    cursor: not-allowed !important;
    opacity: 0.7;
  }

  .MuiButton-startIcon {
    margin-right: 15px;
  }

  &:has(.MuiButton-startIcon) {
    padding-left: 20px !important;
  }

  &.btn-large {
    font-size: 16px !important;
    padding: 13px 35px !important;

    .MuiButton-startIcon {
      margin-right: 20px;
    }

    &:has(.MuiButton-startIcon) {
      padding-left: 30px !important;
    }
  }

  &.btn-purple {
    color: $tekhelet !important;
    box-shadow: 5px 5px 0px 0px $jordy-blue;

    &:hover {
      box-shadow: 7px 7px 0px 0px $jordy-blue;
    }
  }

  &.btn-vanilla {
    color: $earth-yellow !important;
    box-shadow: 5px 5px 0px 0px $vanilla;

    &:hover {
      box-shadow: 7px 7px 0px 0px $vanilla;
    }
  }

  &.btn-red {
    color: #C8102E !important;
    box-shadow: 5px 5px 0px 0px #C8102E;

    &:hover {
      box-shadow: 7px 7px 0px 0px #C8102E;
    }
  }

  &.btn-icon {
    padding: 0 !important;
    width: 45px;
    height: 45px;

    svg {
      font-size: 22px !important;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.MuiAlert-root {
  color: rgba(255, 255, 255, 0.7) !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 10px !important;
  align-items: center;
  font-family: "Lato", sans-serif !important;
  backdrop-filter: blur(3px);

  b {
    color: white !important;
  }

  svg {
    font-size: 28px;
  }

  .MuiAlert-action {
    padding-left: 30px;
    padding-top: 0;

    .MuiButton-root {
      font-family: "Lato", sans-serif !important;
      color: white;
      font-weight: bold;
      text-transform: none;
      text-wrap: nowrap;
      align-items: center;

      svg {
        color: white !important;
      }
    }
  }

  &.MuiAlert-standardError {
    border: 1px solid #BC4B51;

    svg {
      color: #BC4B51 !important;
    }
  }

  &.MuiAlert-standardSuccess {
    border: 1px solid #117E32;

    svg {
      color: #117E32 !important;
    }
  }

  &.MuiAlert-standardWarning {
    border: 1px solid #B89600;

    svg {
      color: #B89600 !important;
    }
  }

  &.MuiAlert-standardInfo {
    border: 1px solid #5FA8D3;

    svg {
      color: #5FA8D3 !important;
    }
  }
}

label {
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
}

span.required {
  color: $jordy-blue;
}

input, select, textarea {
  width: 100%;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: 5px;
  padding: 15px 20px !important;
  height: unset;
  line-height: 16px;
  backdrop-filter: blur(3px);
}

.MuiStack-root {
  padding-top: 0 !important;
}

.MuiTextField-root {
  width: 100%;
  box-sizing: border-box;

  .MuiInputBase-root {
    @extend input;
    padding: 0 !important;

    input {
      padding: 13px 20px !important;
    }

    button {
      margin-right: 0;

      svg {
        color: white;
      }
    }
  }
}

.MuiTelInput-TextField {
  .MuiInputBase-root {
    input {
      padding-left: 0px !important;
    }

    button {
      margin-left: 10px;
    }
  }
}

.base-NumberInput-root {
  position: relative;

  input {
    z-index: 1;
  }

  button {
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 5px;
    height: 18px;
    border-radius: 3px;
    line-height: 11px;
    cursor: pointer;
    z-index: 2;

    &.base-NumberInput-decrementButton {
      bottom: 5px;
    }

    &.base-NumberInput-incrementButton {
      top: 5px;
      transform: rotate(180deg);
    }
  }
}

.checkbox-group {
  label {
    padding-left: 35px;
    position: relative;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding-top: 2px;
    cursor: pointer;

    svg {
      vertical-align: bottom;
      font-size: 20px;
    }

    &:before {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      content: '✔\fe0e';
      width: 22px;
      height: 22px;
      border-radius: 3px;
      font-size: 14px;
      background: white;
      transition: all 0.1s ease-in-out;
      text-align: center;
      color: white;
    }
  }

  input:checked + label:before {
    box-shadow: 3px 3px 0px 0px $earth-yellow;
    color: $tekhelet;
  }
}

a {
  color: inherit;

  &.nice-link {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: $jordy-blue;
    text-shadow: 2px 2px 0px $tekhelet;
    transition: 0.3s ease all;
    display: inline-block;

    &:hover {
      transform: translateY(-3px) !important;
    }
  }
}

.MuiCircularProgress-root {
  display: block !important;
  margin: 0 auto !important;
  color: $jordy-blue !important;
}

.section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-shadow: 2px 2px 0px $tekhelet;
    margin: 0;
  }
}

.box {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 30px;
  backdrop-filter: blur(2px);
  color: #999;
  font-family: "Lato", sans-serif;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;

  h3 {
    margin: 0;
    color: white;
    font-family: "Oswald", sans-serif;
  }

  p {
    margin: 0;
    padding-top: 10px;
  }

  svg.box-icon {
    font-size: 50px;
    color: $vanilla;
    margin-bottom: 10px;
  }
}

p {
  color: #999;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}

.MuiDialogTitle-root{
  font-family: "Oswald", sans-serif !important;
}

.terms-and-conditions {
  line-height: 200%;
  color: rgba(255,255,255,0.8);
  padding: 30px;
  backdrop-filter: blur(5px);
  background: rgba(255,255,255,0.06);
  border-radius: 10px;
  border: 1px solid rgba(255,255,255,0.3);

  b {
    font-weight: 800;
    color: white;
  }
}