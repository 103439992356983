.topup {
  .amount-input{
    .MuiInputAdornment-root {
      margin-left: 20px;

      .MuiTypography-root {
        color: white;
        font-weight: bold;
        font-size: 24px !important;
      }
    }

    input{
      font-size: 24px !important;
      padding-left: 0 !important;
    }
  }

  small{
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-style: italic;
    color: white;
    margin-top: 10px;
    opacity: 0.7;
    display: block;
  }
}