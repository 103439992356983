@import "../../colors";

.header{
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  padding: 20px 0;
  z-index: 50;

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo{
      max-height: 50px;
      cursor: pointer;
    }

    .header-user{
      display: flex;
      align-items: center;
      cursor: pointer;

      .user-info{
        font-family: "Lato", sans-serif;
        margin-left: 15px;
        font-size: 14px;
        color: rgba(255,255,255,0.8);

        span{
          font-family: "Oswald", sans-serif;
          display: block;
          font-size: 16px;
          color: white;
        }
      }
    }

    .header-nav{
      display: flex;
      align-items: center;

      a{
        color: white;
        text-transform: uppercase;
        padding: 0;
        text-decoration: none;
        margin-right: 40px;
        font-weight: 600;
        transition: 0.3s ease all;
        font-family: "Oswald", sans-serif;

        &:not(.btn):hover{
          color: $jordy-blue;
          transform: translate(-2px, -2px);
          text-shadow: 4px 4px 0px $tekhelet;
        }

        &.menu-link svg{
          font-size: 20px;
          display: inline-block;
          vertical-align: sub;
        }

        span.mobile-only {
          display: none;
        }

        &.backoffice-link {
          margin-left: 20px;
        }
      }

      .header-close {
        display: none;
      }

      @media(max-width: 768px){
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: $tekhelet;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        box-sizing: border-box;
        transition: 0.3s ease all;

        &.closed {
          transform: translateX(100%);
          opacity: 0;
        }

        .header-user {
          border-bottom: 1px solid rgba(255,255,255,0.1);
          padding-bottom: 20px;
          margin-bottom: 20px;
          width: 100%;

          a {
            margin-bottom: 0 !important;
            border: 0;
            padding: 0;
          }
        }

        .header-close {
          display: unset;
          padding: 0;
          border: 0;
          background: transparent;
          color: white;
          font-size: 35px;
          width: fit-content;
          margin-left: -5px;
          margin-bottom: 10px;
        }

        a {
          box-sizing: border-box;

          &.menu-link {
            width: 100%;

            svg {
              margin-left: 5px;
            }
          }

          &:not(:last-child){
            border-bottom: 1px solid rgba(255,255,255,0.1);
            padding-bottom: 20px;
            margin-bottom: 20px;
          }

          span.mobile-only {
            display: inline;
          }

          &.backoffice-link {
            margin-left: 0;

            span {
              margin-left: 15px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .header-burger{
      display: none;
      color: white;
      padding: 0;
      border: 0;
      background: transparent;
      font-size: 30px;

      svg{
        display: block;
      }

      @media(max-width: 768px){
        display: block;
      }
    }
  }
}