@import "../../colors";

.account{
  .box{
    height: calc(100% - 60px);

    .wallet-balance{
      color: white;
      font-weight: 800;
      font-size: 28px;
      margin: 10px 0;
      display: flex;
      align-items: center;

      svg{
        margin-right: 10px;
        color: $vanilla;
      }
    }

    small{
      font-style: italic;
      font-size: 12px;
      display: block;
      line-height: 17px !important;
    }

    .bar-activity{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      padding: 15px 0;

      .amount{
        color: white;
        font-weight: 800;
        font-size: 18px;
      }

      &:not(:last-of-type){
        border-bottom: 1px solid rgba(255,255,255,0.2);
      }

      &:first-of-type{
        margin-top: 5px;
      }

      .MuiChip-root{
        background-color: $vanilla;
        font-weight: 800;
        font-size: 10px;
        height: 18px;
        padding: 0 5px;
        margin-left: 10px;

        svg{
          vertical-align: text-top;
        }
      }
    }

    .MuiPagination-root{
      .MuiPaginationItem-root {
        color: rgba(255,255,255,0.6) !important;

        &.Mui-selected{
          color: white !important;

          span{
            background: rgba(255,255,255,0.1);
          }
        }
      }
    }

    .qr{
      width: 150px;
      height: 150px;
      padding: 20px;
      background: white;
      border-radius: 10px;
      margin: 20px auto 0 auto;
      display: block;
    }
  }
}