@import "../../colors";

.hero {
  min-height: 90vh;
  background-image: url("../../../public/hero.png");
  background-size: cover;
  background-position: bottom;
  position: relative;
  overflow-x: hidden;

  img.rug {
    margin-top: 30vh;
    max-height: 50px;
    filter: grayscale(100%) brightness(0) invert(1);

    @media(max-width: 768px) {
      margin-top: 200px;
    }
  }

  span.hero-subtitle {
    font-family: "Oswald", sans-serif;
    font-size: 36px;
    font-weight: 200;
    margin: 0;
    margin-bottom: 25px;
    color: white;
    text-transform: none;
    display: block;
  }

  h1.hero-title {
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    color: $vanilla;
    line-height: 48px;

    @media(max-width: 767px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  span.outline-headline {
    display: block;
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    line-height: 48px;
    margin-top: -9px;
    font-family: "Oswald", sans-serif;

    @media(max-width: 767px) {
      font-size: 36px;
      line-height: 38px;
    }
  }

  p {
    max-width: 600px;
    font-size: 20px;
    padding: 0;
    margin-top: 30px;
  }

  .bands {
    width: 100vw;
    height: auto;
    margin-top: 80px;
    //margin-bottom: -30px;
    overflow-x: hidden;

    img {
      width: 100vw;

      @media(max-width: 768px) {
        width: 200%;
        margin-left: -50%;
      }
    }
  }

  .circle-text {
    display: block;
    position: absolute;
    right: -30px;
    top: 20%;
    height: 150px;
    animation: spin 20s linear infinite;

    @media(max-width: 768px) {
      top: 90px;
    }
  }
}

.homepage {
  h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    color: white;
    line-height: 40px;
    text-shadow: 4px 4px 0px $tekhelet;
    text-align: center;

    &.event-title {
      font-size: 32px;
      text-align: left;
    }
  }

  h3 {
    font-family: "Lato", sans-serif !important;
    text-align: center;
    font-size: 16px;
    font-style: italic;
    opacity: 0.7;
  }


  .event-block {
    position: relative;
    box-shadow: 10px 10px 0px 0px $vanilla;
    transition: 0.3s ease all;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    border-radius: 10px;

    &:hover {
      box-shadow: 15px 15px 0px 0px $vanilla;
      transform: translate(-3px, -3px);
    }

    h4 {
      position: absolute;
      bottom: 25px;
      left: 30px;
      margin: 0;
      font-size: 24px;
    }

    &.barbecue {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.73)), url('../../../public/barbecue_featured.jpeg');
    }

    &.weekend {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.73)), url('../../../public/weekend_featured.jpeg');
    }

    &.social {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.73)), url('../../../public/social_featured.jpeg');
    }
  }

  .event-image {
    img:not(#react-modal-image-img) {
      border-radius: 10px;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 30px;

    li {
      margin-top: 10px;
    }
  }

  .marquee {
    margin-top: 130px;

    img {
      max-height: 200px;
      margin-right: 30px;
      border-radius: 10px;
    }
  }
}

.packing-list{
  b:not(:first-child){
    margin-top: 10px;
    display: block;
  }

  .item {
    margin-top: 5px;
    line-height: 30px;

    span {
      width: 30px;
      height: 30px;
      background: rgba(0,0,0,0.1);
      border-radius: 5px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-right: 10px;
    }
  }
}
